<script>
import { getBatchesList } from "@/api/batches";
import { changeBatchesList } from "@/api/batches";

export default {
  name: "FileBatchesSettings",
  data() {
    return {
      isVisible: true,
      batches: [],
    };
  },
  created() {
    this.getBatches();
  },
  methods: {
    async getBatches() {
      try {
        const { data } = await getBatchesList();
        this.batches = data || [];
      } catch (error) {
        console.log(error);
      }
    },
    async changeBatch(batch_id, is_active) {
      try {
        const { data } = await changeBatchesList(batch_id, is_active);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>

<template>
  <el-drawer
    size="600"
    title="Партия файлов"
    v-model="isVisible"
    @close="close"
  >
    <div class="h-full overflow-y-auto flex flex-col gap-y-2.5">
      <div
        v-for="batch in batches"
        :key="batch._id"
        class="border border-solid border-gray-200 p-2 px-4 rounded-lg flex items-center w-full justify-between bg-gray-50"
      >
        <div class="text-sm text-black">{{ batch.name || "-" }}</div>
        <div>
          <el-switch
            v-model="batch.is_active"
            @change="() => changeBatch(batch._id, batch.is_active)"
            inline-prompt
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="true"
            :inactive-value="false"
          ></el-switch>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<style scoped lang="scss"></style>
